@use '../../../mixin' as mx;
@use '../../../setting' as st;

.operation_log {
  .main_cnt {
    min-width: 530px;
  }
  &__body {
    padding: calc(2em + 2px);
    background-color: #ebebeb;
    @include mx.flex_box;
    @include mx.flex_direction_column;
    gap: 3em;
    position: relative;

    &__wrap {
      padding: 0 clamp(1rem, 5vw, 5rem);
    }

    &__row {
      @include mx.flex_box;
      &.row_forms {
        .forms {
          @include mx.flex_box;
          @include mx.flex_container_wrap;
          gap: 0.5em 1em;
        }
      }
      &.row_period {
        @include mx.flex_container_wrap;
        @include mx.flex_space_between;
        gap: 0.5em 0;
        .period_form__tilde {
          margin-right: -2em;
        }
        .period_form__announce {
          color: st.$error_text_color;
          padding-left: 7.75em;
          margin-top: 0.25em;
        }
        > button {
          font-size: 100%;
          height: calc(3em - 3px);
          min-width: 10.5em;
          max-width: 10.5em;
          margin-left: 2em;
        }
      }

      .labeled_form {
        .select,
        .date_picker {
          height: calc(3em - 2px);
        }
        .select {
          width: calc(14em - 4px);
          background-color: #fff;
        }

        &__label {
          $label_width: 7em;
          min-width: $label_width;
          max-width: $label_width;
          text-align: right;
        }

        .for_select {
          height: 100%;
          select {
            width: 100%;
            height: 100%;
          }
          &::after {
            top: 0;
            bottom: 0;
            margin: auto;
            transform: translateY(50%);
          }
        }

        &.user_name {
          .react_select {
            width: min(31vw, 24.4286em);
            min-height: calc(3em - 2px);
          }
        }
      }
    }
  }

  .delete_btn {
    font-size: 100%;
    // position: absolute;
    // right: calc(2em + 2px);
    margin-left: auto;
    min-width: 45px;
    width: 45px;
    margin-left: auto;
    .button_icon {
      height: 1.25em;
    }
  }

  .date_box {
    .input_wrap {
      input[type="date"] {
        &::-webkit-calendar-picker-indicator {
          opacity: 0;
          position: relative;
          background-color: #ff0000;
          width: 25px;
          height: 25px;
          z-index: 2;
          cursor: pointer;
        }
      }
      img.calendar {
        z-index: 1;
        position: absolute;
        top: 7px;
        right: 11px;
      }
    }
  }
}
