.companyDetail {
  .item_cnt {
    &.operationMember {
      align-items: center !important;
      .label {
        min-width: 5em;
        max-width: 5em;
        text-align: right;
        &:first-of-type {
          margin-right: 1em;
        }
      }
        button {
          margin-left: 3em;
        }
    }
  }
}