@charset "UTF-8";
@use "./mixin.scss" as *;
@import "./setting.scss";
@import "./common.scss";
@import "./form.scss";

/*--------------------------------*/
body {
  @include fontFamily();
  color: $font_color;
}
#App {
  box-sizing: border-box;
  display: flex;
  position: relative;
  height: 100vh;
  .side_bar {
    min-width: 200px;
    width: 200px;
    background-color: $side_bar_background_color;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;

    .logo_box {
      margin: 14px 8px 0;
      border-bottom: 1px solid #fff;
      h1 {
      }
      h2 {
        font-size: 14px;
        margin: 7px 0 16px;
      }
    }
    > ul {
      padding: 25px 8px 25px 12px;
      > li {
        line-height: 18px;
        margin-bottom: 20px;
        font-size: 14px;
        a {
          color: #fff;
          &:hover {
            color: $site_color;
            > img {
              display: none;
              &.on {
                display: inline-block;
              }
            }
          }
        }
        img {
          margin-right: 5px;
        }
        img.on {
          display: none;
        }
        &.selected {
          > a {
            color: $site_color;
            img {
              display: none;
              &.on {
                display: inline-block;
              }
            }
          }
          ul {
            li.selected {
              > a {
                color: $site_color;
              }
            }
          }
        }
        > ul {
          margin-top: 5px;
          padding-left: 30px;
          > li {
            line-height: 25px;
          }
        }
      }
    }
  }
  > .main_cnt {
    /*display: flex;*/
    flex-flow: column;
    flex: 1;
    width: 100%;
    overflow: auto;
    justify-content: space-between;
    > header {
      position: relative;
      display: flex;
      align-items: center;
      height: 50px;
      line-height: 50px;
      padding: 0 10px 0 15px;
      justify-content: space-between;
      color: #fff;
      background-color: $header_background_color;
      > h1 {
        margin: 0;
        font-size: 18px;
        font-weight: normal;
      }
      > div {
        margin-left: auto;
        margin-right: 15px;
      }
      button {
        width: 22px;
        height: 22px;
        padding: 0;
      }
    }
    .inner {
      padding: 15px 15px 30px 15px;
      > h2 {
        /*for portal*/
        color: $font_color;
        font-size: 20px;
      }
      section {
        position: relative;
        > header {
          display: flex;
          align-items: flex-end;
          margin-bottom: 15px;
          h2 {
            color: $font_color;
            font-size: 24px;
          }
          .count {
            display: inline-block;
            margin-left: 15px;
            font-size: 16px;
            line-height: 32px;
            span {
              display: inline-block;
              font-size: 12px;
              &:first-child {
                margin-right: 5px;
              }
            }
          }
          label {
            font-size: 14px;
          }
          button {
            margin-left: auto;
          }
        }
      }
      .table_box {
        margin-bottom: 30px;
      }
    }
  }
  /* 個別 */
  &.information_list,
  &.information_detail,
  &.notification_settings {
    > .main_cnt {
      height: 100%;
      @include flex_box;
      @include flex_direction_column;
      overflow: hidden;
      .inner {
        flex: 1;
        @include flex_box;
        @include flex_direction_column;
        overflow: hidden;
      }
    }
  }
  &.information_list {
    section {
      height: 100%;
      @include flex_box;
      @include flex_direction_column;
      overflow: hidden;
    }
  }
  &.information_detail {
    .inner {
      > .inner {
        overflow: auto;
        gap: 40px 0;
      }
    }
  }
  > footer {
  }
}

.breadcrumb {
  display: flex;
  font-size: 12px;
  margin-bottom: 10px;
  a {
    color: $site_color;
  }
}
/*-----------------------------------------------*/
#App.detail,
#App.edit,
#App.setting,
.direct_access {
  .main_cnt .inner section {
    > header {
      display: block;
      text-align: center;
      padding-top: 20px;
      margin-bottom: 5px;
    }
  }
  .edit_wrap {
    max-width: 870px;
    margin: 0 auto;
    h3 {
      color: $font_color;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .edit_box {
      @include border-default($color: $border_edit_wrap_color);
      padding: 5px;
      margin-bottom: 60px;

      &.for_cadect {
        padding: 20px 20px 10px;
        margin-top: 25px;
        margin-bottom: 15px;
        position: relative;
        .item_wrap.fix {
          position: absolute;
          background-color: #fff;
          height: 50px;
          top: -30px;
          left: 20px;
        }
      }

      &.number_box {
        padding: 40px 20px 20px;
        margin-bottom: 40px;
        position: relative;
        .item_wrap.fix {
          position: absolute;
          background-color: #fff;
          height: 50px;
          top: -25px;
          left: 30px;
          .item_head {
            background-color: #fff;
            padding: 20px 15px 20px 5px;
            width: auto;
            span.required {
              margin-left: 15px;
            }
          }
          .item_cnt {
            display: flex;
            align-items: flex-end;
            padding: 0;
            input {
              width: 90px;
            }
          }
        }
        .item_cnt {
          display: flex;
          align-items: flex-end;
          input {
            width: 90px;
          }
        }
        .number_box {
          margin-top: 50px;
          margin-bottom: 0;
        }
      }
      .item_wrap {
        display: flex;
        .item_head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 300px;
          font-size: 14px;
          font-weight: bold;
          padding: 20px 15px;
          background-color: $item_head_background_color;
          .comment {
            display: inline-block;
            font-size: 12px;
            font-weight: normal;
          }
          &.plus_comment {
            height: 82px;
            + .item_cnt {
              input {
                margin-top: 15px;
              }
            }
          }
        }
        .item_cnt {
          flex: 1;
          font-size: 14px;
          input,
          select {
            margin: 5px 0;
          }
          .indent {
            margin-left: 36px;
          }
          &.flex {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }
          .postal_coad {
            width: 120px;
          }
          label {
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            margin-right: 10px;
            &.fix_size {
              display: inline-block;
              width: 34px;
              text-align: right;
            }
            &.unit {
              margin-left: 5px;
              font-size: 14px;
              vertical-align: bottom;
              line-height: 26px;
            }
          }
          input + label.fix_size {
            margin-left: 30px;
          }
          .comment {
            display: inline-block;
            margin-left: 10px;
            font-size: 12px;
          }
        }
      }
    }
    .date_box {
      margin-bottom: 50px;
      label {
        font-size: 14px;
      }
      .input_wrap {
        position: relative;
        display: inline-block;
        &.disabled {
          background-color: transparent;
        }
        input[type="date"] {
          background-color: transparent;
          padding-right: 0;
          border: none;
          &::-webkit-calendar-picker-indicator {
            opacity: 0;
            position: relative;
            background-color: #ff0000;
            width: 25px;
            height: 25px;
            z-index: 2;
            cursor: pointer;
          }
        }
        img.calendar {
          z-index: 1;
          position: absolute;
          top: 7px;
          right: 11px;
        }
        img.reset {
          width: 20px;
          z-index: 1;
          position: absolute;
          top: 10px;
          right: 42px;
          cursor: pointer;
        }
      }
    }
  }
}
#App.list {
  section {
    > header {
      margin-bottom: 10px !important;
    }
  }
}

#App.edit,
.direct_access {
  section {
    > header {
      margin-bottom: 20px !important;
    }
  }
  .item_cnt {
    padding: 5px 40px 5px 15px;
    label.text_box {
      line-height: 50px;
      font-weight: normal !important;
    }
  }
}

//直接遷移用
.direct_access {
  .dialog {
    .dialog_body {
      .company_info_box {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 25px;
        > div + div {
          margin-top: 10px;
        }
      }
      .edit_wrap {
        width: 100%;
      }
    }
  }
  &.add_back {
    background-color: $form_background_color !important;
    .dialog {
      padding: 0 30px 50px !important;
      header {
        margin: 20px 0 0;
      }
      .dialog_body {
        padding: 0 !important;
      }
    }
  }
}

/*-----------------------------------------------*/
#App.detail {
  section {
    > header {
      display: flex !important;
      flex-wrap: wrap;
      align-items: flex-end;
      margin: 0 60px 40px !important;
      padding: 0 10px 10px 30px;
      border-bottom: 1px solid $border_edit_wrap_color;
      h2 {
        flex: 1;
        color: $font_color;
        font-size: 24px;
        text-align: left;
      }
      .btn_box {
        button {
          width: 140px;
          margin-left: 10px;
          margin-bottom: 5px;
          + button {
          }
        }
      }
    }
  }
  .item_cnt {
    padding: 20px 15px;
  }
  .edit_wrap .edit_box.number_box .item_wrap.fix .item_cnt {
    padding: 20px 15px;
    line-height: 10px;
  }
  .date_box {
    font-size: 16px;
  }
  /* 個別 */
  &.information_detail {
    section {
      > header {
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-bottom: 10px !important;
      }
    }
  }
}

/* orgnization -----------------------------------------------*/
.orgnization_box {
  margin: 15px 50px;
}
.map {
  position: absolute;
  top: 0;
  right: 30px;
  .frame {
    position: relative;
    @include border-default($size: 2px, $color: $border_edit_wrap_color);
    margin-bottom: 10px;
    .line {
      position: absolute;
      border: 2px dashed $site_color;
    }
  }
  .btn_box {
    text-align: center;
    button {
      img {
        &.on {
          display: none;
        }
      }
      &.on {
        img {
          display: none;
          &.on {
            display: block;
          }
        }
      }
    }
  }
}

#orgnization_dialog {
  .dialog_body {
    padding: 0 30px 40px;
    .inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: hidden;
      padding: 0;
      .organization_edit {
        overflow-y: auto;
      }
      footer {
        margin-top: 30px;
      }
    }
  }
}

.dialog {
  .organization_edit {
    display: flex;
    flex-direction: column;
    .edit_box {
      flex: 1;
      padding: 40px 60px 0;
      overflow-y: auto;
      .item_wrap {
        margin-bottom: 15px;
        .item_head {
          line-height: 30px;
          font-weight: bold;
          span.required {
            font-weight: normal;
            margin-left: 5px;
          }
        }
        .item_cnt {
          &.flex {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }
          .postal_coad {
            width: 90px;
          }
          label {
            font-size: 14px;
            font-weight: bold;
            margin-right: 10px;
          }
          .comment {
            display: inline-block;
            margin-left: 10px;
            font-size: 12px;
          }
        }
      }
    }
    footer {
      flex: 1;
      margin-top: 15px;
      margin-bottom: 15px; //不要なら削除
    }
  }
  .organization_tree {
    @include flex_box;
    height: 100%;
    overflow-y: hidden;
    margin-top: 20px;
    //width: 800px;
    gap: 20px;
    .tree_wrap {
      width: 560px;
      height: 400px;
      border: 1px solid #707070;
      overflow-y: auto;
      > div {
        text-align: left !important;
      }
    }
    .map {
      top: 0px;
    }

    > .btn_box {
      @include flex_box;
      @include flex_align_end;
      gap: 0 15px;
      > button {
        width: calc(10.5em + 3px);
        height: calc(3em - 3px);
        margin: 0;
      }
    }
  }
}

/*-----------------------------------------------*/
#App.portal {
  .main_cnt {
    background-color: $portal_background_color;
    .inner {
      padding: 25px 30px;
      h2 {
        margin-bottom: 15px;
      }
    }
  }
  .tip_box {
    align-items: center;
  }
}
//企業詳細 状況一覧
#App.status {
  .main_cnt {
    .inner {
      h2 {
        margin-left: 15px;
      }
    }
  }
  .tip_wraper {
    > div {
      height: 260px !important;
      .tip {
        .text_box.fix_height {
          height: 85px;
          h3 {
            margin-top: 16px;
          }
          .count {
            line-height: 35px;
          }
          &.second {
            h3 {
              margin-top: 0;
            }
            .count {
              line-height: 50px;
            }
          }
        }
      }
    }
    &.tip_storege {
      .tip {
        position: relative;
        text-align: center;
        margin: 10px 10px 0 10px;
        h3 {
          height: 30px !important;
          margin-bottom: 0;
        }
        .text_box {
          bottom: 66px !important;
          h4 {
            height: 24px;
            margin-bottom: 10px;
          }
          .count {
            height: 36px;
          }
          .total {
            height: 18px;
          }
        }
      }
    }
  }
}

/*-----------------------------------------------*/
#App.company.list {
  .table_box {
    table {
      thead {
        tr {
          th:nth-child(9) {
            i {
              //display: none;
            }
          }
        }
      }
    }
  }
}

/*-----------------------------------------------*/
//設定
#App.setting {
  .main_cnt {
    .inner {
      max-width: 1280px;
      width: 80%;
      margin: 60px auto;
      header {
        border-bottom: 1px solid $border_edit_wrap_color;
        padding-bottom: 25px;
        h2 {
        }
        div {
          margin-top: 10px;
          font-size: 16px;
        }
      }
      .edit_box {
        width: 420px;
        margin: 30px auto 60px;
        .item_wrap {
          &.icon_edit {
            //アイコン領域
            margin: 60px 0;
            display: flex;
            align-items: center;
            .item_head {
              flex-basis: 100px;
            }
            .item_cnt {
              text-align: center;
              img {
                width: 70px;
                height: 70px;
                object-fit: cover;
                border-radius: 50%;
              }
            }
          }
          .item_head {
            font-weight: bold;
            margin-bottom: 5px;
            font-size: 14px;
          }
          .item_cnt {
            input {
              width: 100%;
            }
          }
          + .item_wrap {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
