@charset "utf-8";
@use './mixin' as mx;

*,
::before,
::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  margin: 0;
}
img {
  vertical-align: middle;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

hr {
  height: 1px;
  margin: 6px 0;
  border: none;
  background: $hr_color;
  opacity: 1;
}

a {
  text-decoration: none;
  color: $font_color;
}
button {
  cursor: pointer;
  border: none;
  background-color: inherit;
  vertical-align: middle;
  //padding: 0;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: $scrollbar_bg_color;
  border: none;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: $site_color;
  border-radius: 4px;
  box-shadow: none;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

// レイアウト系
.mb_60 {
  margin-bottom: 60px;
}
.gap_sm {
  gap: 0.5rem;
}
.center_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.v_center_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
// レイアウト系 ここまで

.alert {
  position: relative;
  padding: 0;
  //margin-bottom: 1rem;
  border: none;
  border-radius: 0;
  color: $error_text_color;
  //font-weight: bold;
}
.emphasis {
  font-weight: bold;
}

.inline_block {
  display: inline-block;
}
.align_center {
  text-align: center;
}
.align_left {
  text-align: left;
}
.align_right {
  text-align: right;
}

span.required {
  display: inline-block;
  text-align: center;
  line-height: 20px;
  width: 32px;
  height: 20px;
  font-size: 12px;
  color: #fff;
  background-color: $required_color;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.position_relative {
  position: relative;
}

.box_shadow {
  @include mx.box_shadow();
}

.pager {
  text-align: center;
  button {
    font-size: 14px;
    width: 30px;
    color: $pager_text_color;
    &.now {
      cursor: default;
      pointer-events: none;
      color: $font_color;
    }
  }
}
.d-none {
  /*Same class as bootstrap*/
  display: none !important;
}
.skeleton {
  width: 100%;
  height: 100%;
  min-height: 1rem;
  background: #d9d9d9;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(#fff, 0.5),
      transparent
    );
    position: absolute;
    top: 0;
    left: 0;
    animation: skeleton-animation 1.2s linear infinite;
  }
}

@keyframes skeleton-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.up_down_toggle {
  font-size: 1rem;
  width: 1em;
  height: 1em;
  @include mx.flex_box;
  @include mx.flex_all_center;
  &::before,
  &::after {
    display: block;
    content: "";
    position: absolute;
    user-select: none;
  }
  &::before {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: #17a3b4;
  }
  &::after {
    $shape_height: 0.4em;
    width: 0;
    height: 0;
    border-top: calc(#{$shape_height} / 4 * 3) solid transparent;
    border-right: calc(#{$shape_height} / 4 * 3) solid transparent;
    border-bottom: $shape_height solid #fff;
    border-left: calc(#{$shape_height} / 4 * 3) solid transparent;
    transform: translateY(-30%);
  }
  &.close {
    &::before {
      transform: rotate(180deg);
    }
    &::after {
      transform: translateY(30%) rotate(180deg);
    }
  }
}

.info_edit_dialog {
  .dialog {
    header {
      border: none;
    }
    &_body {
      .inner {
        // overflow: hidden;
      }
    }
  }
  &__body {
    max-height: 63vh;
    @include mx.flex_box;
    @include mx.flex_direction_column;
    overflow: hidden;
    gap: 3em;
    padding-bottom: 2em;
    .information_editor {
      flex: 1;
      overflow: auto;
      width: 100%;
      border: 1px solid #707070;
      &__inner {
        border: none;
      }
    }
  }
  &__actions {
    font-size: 1rem;
    @include mx.flex_box;
    @include mx.flex_content_center;
    gap: 0 1em;
    button {
      font-size: 100%;
      margin: 0 !important;
      width: 10.5em;
      height: calc(3em - 3px);
    }
  }
}

.detail_box {
  max-width: 870px;
  margin: 0 auto;
  &__inner {
    @include mx.border-default($color: $border_edit_wrap_color);
    padding: 5px;

    .item_wrap {
      display: flex;
      .item_head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 250px;
        width: 250px;
        font-size: 14px;
        font-weight: bold;
        padding: 20px 15px;
        background-color: $item_head_background_color;
      }
      .item_cnt {
        flex: 1;
        font-size: 14px;
        padding: 20px 25px;
        word-break: break-all;
        overflow: auto;
      }
    }
  }
}

.border_wrap {
  border: 1px solid #707070;
  padding: 0.3125rem;
}
